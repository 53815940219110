.cp-saturation {
  min-width: 450px;
  width: 75vw;
  height: 12px;
  /* This covers the full range of hues */
  background-image: linear-gradient(to right, white, transparent);
  border-radius: 999px;
  /* This allows us to position an absolute
             indicator over the map */
  position: relative;
  cursor: crosshair;
}
.cp-saturation-indicator {
  width: 15px;
  height: 15px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  transform: translate(-7.5px, -2px);
  position: absolute;
}
