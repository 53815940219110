.cp-wrapper {
  display: flex;
  height: 40px;
  width: 100%;
  padding-top: 4px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  z-index: 10;
}
.cp-parent {
  display: block;
  height: 40px;
  width: 100%;
  position: relative;
}
.cp-scale {
  position: absolute;
  width: 100%;
  height: 75%;
  top: 20%;
  align-self: center;
}
