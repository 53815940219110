.cp-hue {
  min-width: 450px;
  width: 75vw;
  height: 400px;
  cursor: grab;
  border-radius: 4px;
  /* This allows us to position an absolute
       indicator over the map */
  position: relative;
  cursor: crosshair;
}

.cp-free-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
